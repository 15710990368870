// src/components/GifComponent.js
import React from "react";
import rayagif1 from "assets/img/gif/rayagif1.gif";

// CSS Styles
const styles = `
  .gif-container {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }

  .gif {
    width: 110px;
    height: 110px;
    border-radius: 8px; /* Optional: Add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  }
`;

// Inject styles into the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

function GifComponent() {
  return (
    <div className="gif-container">
      <img src={rayagif1} alt="Looping GIF" className="gif" />
    </div>
  );
}

export default GifComponent;
