import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

function AudioPlayer({ src }) {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const playAudio = async () => {
      if (audioRef.current) {
        try {
          await audioRef.current.play();
          setIsPlaying(true);
        } catch (error) {
          console.error("Autoplay was prevented:", error);
          // Autoplay was blocked, show a play button or handle accordingly
        }
      }
    };

    playAudio();
  }, []);

  const handlePlay = async () => {
    if (audioRef.current) {
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error("Play failed:", error);
      }
    }
  };

  return (
    <div>
      <audio ref={audioRef} controls hidden loop>
        <track kind="captions" src={src} srcLang="en" label="English" />
        <source src={src} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>

      {/* Show a play button if autoplay was blocked */}
      {!isPlaying && (
        <Button onClick={handlePlay} style={{ display: "block" }}>
          Play Audio
        </Button>
      )}
    </div>
  );
}

export default AudioPlayer;

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};
