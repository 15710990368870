import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import UserContext from "services/UserContext";
import ShopOwner from "layouts/ShopOwner";

import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/Admin";
import { ShopProvider } from "services/ShopContext";
import { PaymentProvider } from "services/PaymentContext";
import SetPassword from "views/pages/SetPassword/SetPassword";
import AudioPlayer from "components/Audio/Audio";
import GifComponent from "components/Gif/Gif";

// List of songs
const songs = ["/rayasong.mp3", "/rayasong2.mp3", "/rayasong3.mp3"];

export default function App() {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [currentSong, setCurrentSong] = useState("");

  useEffect(() => {
    const randomSong = songs[Math.floor(Math.random() * songs.length)];
    setCurrentSong(randomSong);
  }, []);

  useEffect(() => {
    if (user?.IsOneTimePassword === true) {
      history.replace(
        user.IsOneTimePassword === true
          ? "/setPassword"
          : "/shopOwner/dashboard"
      );
    }
  }, [user, history]);

  return (
    <ShopProvider>
      <PaymentProvider>
        <div>
          {/* Conditionally render the GifComponent */}
          {user && <GifComponent />}

          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            {user && (
              <Route
                path="/shopOwner"
                render={(props) => <ShopOwner {...props} />}
              />
            )}
            <Route path="/setPassword" component={SetPassword} />
            {user && <Redirect to="/shopOwner/dashboard" />}
            {!user && <Redirect to="/auth/Login" />}
          </Switch>
          {user && <AudioPlayer id="audio-player" src={currentSong} />}
        </div>
      </PaymentProvider>
    </ShopProvider>
  );
}
