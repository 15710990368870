/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Switch from "react-ios-switch/lib/Switch";

function ViewRefundDetails() {
  const { orderId, id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { data: returnData, refetch: returnRefetch } = useQuery(
    `/shop/${shopID}/${id}`,
    async () => Axios.get(`/shop/${shopID}/${id}`)
  );

  const dept = [];

  if (JSON.parse(localStorage.getItem("deptProfile")) !== null) {
    dept.push(JSON.parse(localStorage.getItem("deptProfile")));
  }

  const { data: orderDt } = useQuery(
    `/shop/${shopID}/Order/${orderId}`,
    async () => Axios.get(`/shop/${shopID}/Order/${orderId}`)
  );
  const { data: logisticDt } = useQuery(
    `/shop/${shopID}/LogisticList`,
    async () => Axios.get(`/shop/${shopID}/LogisticList`)
  );

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    const text = returnData?.data.ReturnRefundMeganShipBackInfo.TrackingNumber;
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const [showModal, setShowModal] = useState({
    rID: "",
    open: false,
  });

  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  const [updaterefundS, setUpdaterefundS] = useState("");
  const [checked, setChecked] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    Axios.put(`/shop/${shopID}/RequestApproval/${id}`, {
      Status: updaterefundS,
      ShipBackFeesPayment: checked,
    })
      .then((response) => {
        // Handle the response from the API
        toast.success(response.data.MESSAGE);
        setShowModal({
          ...showModal,
          open: false,
        });
        returnRefetch();
      })
      .catch((error) => {
        // Handle error
        toast.error(error.data.MESSAGE);
        setShowModal({
          ...showModal,
          open: false,
        });
      });
  };

  const [updateLogisticEdit, setUpdateLogisticEdit] = useState(false);

  const validationSchema = Yup.object().shape({
    LogisticName: Yup.string().required("Logistic is required"),
    TrackingNo: Yup.string().required("TrackingNo is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      LogisticName: "",
      TrackingNo: "",
    },
    validationSchema,
    onSubmit: (data) => {
      const data3 = {
        LogisticName: data.LogisticName,
        TrackingNo: data.TrackingNo,
      };
      Axios.put(`shop/${shopID}/ReturnTrackingNo/${id}`, data3)
        .then((res) => {
          setUpdateLogisticEdit(false);
          toast.success(`Updated successfull ${res.data.MESSAGE}`);
          returnRefetch();
          // resetForm();
        })
        .catch((error) => {
          toast.error(`Updated failed! ${error.response.data[0].MESSAGE}`);
        });
    },
  });

  // get the products that request to return from order
  const productRelated = React.useMemo(() => {
    if (orderDt && returnData) {
      return orderDt.data.Product.find(
        (x) => x.ID === returnData.data.Product.ID
      );
    }
    return null;
  }, [orderDt, returnData]);

  // use to calculate after sub discount price (display only)
  const discountPrice = React.useMemo(() => {
    if (productRelated) {
      const price =
        productRelated.TotalOrderCost.toFixed(2) -
        orderDt.data.Discount.toFixed(2);
      return price;
    }
    return 0;
  }, [productRelated, orderDt]);
  // use to calculate latest price (display only)
  const totalPrice = React.useMemo(() => {
    if (productRelated) {
      let price = discountPrice;
      if (checked) {
        price += orderDt?.data.Region.includes("West") ? 15 : 18;
      }
      return price.toFixed(2);
    }
    return 0;
  }, [checked, productRelated]);

  useEffect(() => {
    if (orderDt) {
      if (orderDt.data.Region.includes("Self-Pickup")) {
        setChecked(false);
      }
    }
  }, [orderDt]);

  if (productRelated) {
    return (
      <div className="content">
        <Button
          style={{ backgroundColor: "#51BCDA" }}
          className="mt-2 p-2 ml-5"
          onClick={handleBackClick}
          size="sm"
        >
          Back
        </Button>
        <Card className="card-stats card-dark">
          <CardBody className="mt-3">
            <p className="bg-info p-2 pl-4">User Information</p>
            <Row className="mt-3">
              <Col md="6">
                <Card
                  className="card-stats card-dark"
                  style={{ border: "1px solid #51BCDA" }}
                >
                  <CardBody className="text-white">
                    <p>Order Number: {orderDt?.data.ID}</p>
                    <p>Invoice Number: {orderDt?.data.Invoice}</p>
                    <p>Status: {`${returnData?.data.status}`}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                {returnData?.data.shopReturnInformationGetDto.DropOffInfo !==
                null ? (
                  <Card
                    className="card-stats card-dark"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <CardBody className="text-white">
                      <p>Refund Number: {returnData?.data.ID}</p>
                      <p>
                        {`Name:
                      ${returnData?.data.shopReturnInformationGetDto.DropOffInfo.DropOffPersonDetails.DropOffPersonName}`}
                      </p>
                      <p>
                        {` ContactNo:
                      ${returnData?.data.shopReturnInformationGetDto.DropOffInfo.DropOffPersonDetails.ContactNo}`}
                      </p>
                    </CardBody>
                  </Card>
                ) : (
                  <Card
                    className="card-stats card-dark"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <CardBody className="text-white">
                      <p>
                        {`Name:
                  ${returnData?.data.shopReturnInformationGetDto.CourierServiceInfo.FullName}`}
                      </p>
                      <p>
                        {` ContactNo:
                      ${returnData?.data.shopReturnInformationGetDto.CourierServiceInfo.ContactNo}`}
                      </p>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
            <Card
              className="card-stats card-dark "
              style={{ border: "1px solid #51BCDA" }}
            >
              <CardBody>
                <Row>
                  <Col md="6">
                    <div className="text-white">
                      <h6
                        className="font-weight-bold text-left text-capitalize text-white"
                        style={{ fontSize: "14px" }}
                      >
                        Billing Information
                      </h6>
                      <p
                        className="text-capitalize font-weight-bold "
                        style={{ fontSize: "15px" }}
                      >
                        {orderDt?.data.Name}
                      </p>
                      <p style={{ fontSize: "15px" }}>
                        {`H/P: ${orderDt?.data.Contact}`}
                        <br />
                        {orderDt?.data.Email}
                        <br />
                        {orderDt?.data.BillingAddress}
                        <br />
                      </p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <h6
                        className="font-weight-bold text-capitalize"
                        style={{ fontSize: "14px" }}
                      >
                        Shipping Information
                      </h6>
                      <p
                        className="text-capitalize font-weight-bold"
                        style={{ fontSize: "15px" }}
                      >
                        {orderDt?.data.Name}
                      </p>
                      <p style={{ fontSize: "15px" }}>
                        {`H/P: ${orderDt?.data.Contact}`}
                        <br />
                        {orderDt?.data.Email}
                        <br />
                        {orderDt?.data.ShippingAddress}
                        <br />
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card
              className="card-stats card-dark "
              style={{ border: "1px solid #51BCDA" }}
            >
              <CardBody>
                <Row>
                  <Col md="6">
                    <div className="text-white">
                      <h6
                        className="font-weight-bold text-left text-capitalize text-white"
                        style={{ fontSize: "14px" }}
                      >
                        Refund Information
                      </h6>
                      <p
                        className="text-capitalize font-weight-bold "
                        style={{ fontSize: "15px" }}
                      >
                        Reasons
                        {returnData?.data.returnReasonList?.map((reason) => (
                          <div>
                            <ul>
                              <li>{reason}</li>
                            </ul>
                          </div>
                        ))}
                      </p>
                      <p>{`Comment : ${returnData?.data.comment}`}</p>
                      {returnData?.data.ReturnRefundMeganShipBackInfo !==
                      null ? (
                        <>
                          <p className="font-weight-bold">
                            Shipped Back Logistic Info
                          </p>
                          <p>
                            Courier Name:&nbsp;
                            {
                              returnData?.data.ReturnRefundMeganShipBackInfo
                                .Logistic
                            }
                          </p>
                          <p>
                            Tracking NO:&nbsp;
                            {
                              returnData?.data.ReturnRefundMeganShipBackInfo
                                .TrackingNumber
                            }
                            {!isCopied && (
                              <Button
                                className="btn btn-link p-0 text-left text-white text-capitalize"
                                onClick={handleCopy}
                                style={{ marginLeft: "10px" }}
                              >
                                Copy
                              </Button>
                            )}
                            {isCopied && (
                              <span
                                className="btn btn-link p-0 text-left text-white text-capitalize"
                                style={{ marginLeft: "10px" }}
                              >
                                Copied!
                              </span>
                            )}
                          </p>
                        </>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <h6
                        className="font-weight-bold text-capitalize"
                        style={{ fontSize: "14px" }}
                      >
                        Customer Attachment Details
                      </h6>
                      <div>
                        {returnData?.data.FileUrlList.map((img) => (
                          <p>
                            <a
                              href={img}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {img.length > 25
                                ? `${img.substring(0, 25)}...`
                                : img}
                            </a>
                          </p>
                        ))}
                      </div>
                      <p>
                        {`Return Method:
                      ${returnData?.data.shopReturnInformationGetDto.ReturnMethod}`}
                      </p>
                      {returnData?.data.shopReturnInformationGetDto
                        .DropOffInfo !== null ? (
                        <>
                          <h6
                            className="font-weight-bold text-capitalize"
                            style={{ fontSize: "14px" }}
                          >
                            Branch
                          </h6>
                          <p>
                            {`DropOff Date :
                      ${moment(
                        returnData?.data.shopReturnInformationGetDto.DropOffInfo
                          .DropOffDate
                      ).format("DD-MMM-YYYY")}`}
                          </p>
                          <p>
                            {
                              returnData?.data.shopReturnInformationGetDto
                                .DropOffInfo.BranchDetails.BranchName
                            }
                          </p>
                          <p>
                            {
                              returnData?.data.shopReturnInformationGetDto
                                .DropOffInfo.BranchDetails.BranchAddress
                            }
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            {`Courier Name:
                  ${returnData?.data.shopReturnInformationGetDto.CourierServiceInfo.CourierName}`}
                          </p>
                          <p>
                            {`Tracking No:
                  ${returnData?.data.shopReturnInformationGetDto.CourierServiceInfo.TrackingNo}`}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {returnData?.data.InternalStatus === "Pending Ship Back" ? (
              <Row className="justify-content-end">
                <Col md="6">
                  <div>
                    {!updateLogisticEdit ? (
                      <a
                        style={{ fontSize: "12px" }}
                        href="#edit"
                        className="font-weight-bold text-center text-white float-right mr-5 pr-1 border-bottom"
                        onClick={(e) => {
                          e.preventDefault();
                          setUpdateLogisticEdit(true);
                        }}
                      >
                        Update Logistic for Ship Back
                      </a>
                    ) : (
                      <>
                        <a
                          style={{ fontSize: "12px" }}
                          href="#edit"
                          className="font-weight-bold text-center text-white float-right mr-5 pr-1 border-bottom"
                          onClick={(e) => {
                            e.preventDefault();
                            setUpdateLogisticEdit(false);
                          }}
                        >
                          X
                        </a>
                        <p className="m-2">Logistic: </p>
                        <Form onSubmit={formik.handleSubmit}>
                          <Label>Tracking Code</Label>

                          <Input
                            onChange={formik.handleChange}
                            placeholder="TrackingNo"
                            id="TrackingNo"
                            name="TrackingNo"
                            value={formik.valuesTrackingNo}
                            type="text"
                            style={{ border: "1px solid gray" }}
                          />
                          <div className="text-danger">
                            {formik.errors.TrackingNo
                              ? formik.errors.TrackingNo
                              : null}
                          </div>
                          <Label>Logistic Name</Label>
                          <br />
                          <select
                            className="w-100 text-dark"
                            style={{
                              height: "35px",
                              borderRadius: "5px",
                              border: "1px solid black",
                            }}
                            onChange={formik.handleChange}
                            placeholder="LogisticName"
                            id="LogisticName"
                            name="LogisticName"
                            value={formik.values.LogisticName}
                          >
                            <option value="">Select Logistic</option>
                            {logisticDt?.data.map((logistic) => (
                              <option value={logistic} className="text-dark">
                                {logistic}
                              </option>
                            ))}
                          </select>
                          <div className="text-danger">
                            {formik.errors.LogisticName
                              ? formik.errors.LogisticName
                              : null}
                          </div>

                          <Button className="btn btn-primary" type="submit">
                            Submit
                          </Button>
                        </Form>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            ) : null}

            <h6
              className="mt-5 ml-3 font-weight-bold text-left text-capitalize text-white"
              style={{ fontSize: "14px" }}
            >
              Refund Product Information
            </h6>

            <div style={{ overflowX: "auto" }}>
              <table className="mt-5 w-100" style={{ minWidth: "600px" }}>
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Item</td>
                    <td>Before Discount</td>
                    <td>Discount</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src={
                          returnData?.data.Product.ProductImageList[0].ImageUrl
                        }
                        alt=""
                        width="100px"
                        height="100px"
                      />
                    </td>
                    <td className="py-3">
                      <p style={{ wordWrap: "break-word", maxWidth: "30ch" }}>
                        {returnData?.data.Product.Name}
                      </p>
                      <br />
                      <p>
                        {returnData?.data.Product.VariationList[0].VariationSKU}
                        <br />
                        <p>{`Weight = ${returnData?.data.Product.VariationList[0].Weight}`}</p>
                      </p>
                    </td>

                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={productRelated?.TotalOrderCost.toFixed(2)}
                        currency="MYR"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={orderDt?.data.Discount.toFixed(2)}
                        currency="MYR"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={
                          productRelated.TotalOrderCost.toFixed(2) -
                          orderDt.data.Discount.toFixed(2)
                        }
                        currency="MYR"
                      />
                    </td>
                  </tr>
                  <tr>
                    {(returnData?.data.status === "Pending Validation" &&
                      dept[0].Position === "DepartmentHead") ||
                    dept[0].Position === "Supervisor" ? (
                      <td>
                        <Row>
                          <Col xs="12" md="auto">
                            <p style={{ fontSize: "20px" }} className="mr-2">
                              Approved / Rejected with Shipping Fee :
                            </p>
                          </Col>
                          <Col xs="12" md="auto">
                            <Switch
                              checked={checked}
                              onChange={() => {
                                setChecked(!checked);
                              }}
                              disabled={orderDt?.data.Region === "Self-Pickup"}
                            />
                          </Col>
                        </Row>
                      </td>
                    ) : null}
                    <td>
                      <p>Shipping Fee - {orderDt?.data.Region}</p>
                    </td>
                    <td />
                    <td />
                    <td style={{ whiteSpace: "nowrap" }}>
                      {checked ||
                      returnData?.data.status === "Refund Approved" ||
                      returnData?.data.status === "Refund Rejected" ||
                      returnData?.data.status === "Shipped Back" ||
                      (returnData?.data.status === "Ship Back" &&
                        dept[0].Position === "DepartmentHead") ||
                      dept[0].Position === "Supervisor" ? (
                        <p className="position-relative">
                          <span
                            className="position-absolute"
                            style={{ left: "-12px", top: 0 }}
                          >
                            +
                          </span>
                          <CurrencyFormat
                            decimal={2}
                            price={
                              orderDt?.data.Region.includes("West")
                                ? "15"
                                : "18"
                            }
                            currency="MYR"
                          />
                        </p>
                      ) : (
                        <p>
                          <CurrencyFormat
                            decimal={2}
                            price={0}
                            currency="MYR"
                          />
                        </p>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={5}>
                      <hr
                        style={{
                          border: "1px solid #51BCDA",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} />
                    <td>
                      <p>Total : </p>
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={totalPrice}
                        currency="MYR"
                      />
                    </td>
                  </tr>

                  {/* <tr>
                    <td colSpan={3} />
                    <td>Shipping Fee : </td>
                    <td>
                      {checked ||
                      returnData?.data.status === "Refund Approved" ||
                      returnData?.data.status === "Refund Rejected" ||
                      returnData?.data.status === "Shipped Back" ||
                      returnData?.data.status === "Ship Back" ? (
                        <CurrencyFormat
                          decimal={2}
                          price={15}
                          currency="+ MYR"
                        />
                      ) : (
                        <CurrencyFormat decimal={2} price={0} currency="MYR" />
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td colSpan={3} />
                    <td colSpan={2}>
                      <hr
                        style={{
                          border: "1px solid #51BCDA",
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3} />
                    <td>
                      <p>Ewallet : </p>
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={orderDt?.data.Ewallet.toFixed(2)}
                        currency="MYR"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} />
                    <td>
                      <p>Bank Transfer : </p>
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={totalPrice}
                        currency="MYR"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3} />
                    <td colSpan={2}>
                      <hr
                        style={{
                          border: "1px solid #51BCDA",
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {(returnData?.data.status === "Pending Validation" &&
              dept[0].Position === "DepartmentHead") ||
            dept[0].Position === "Supervisor" ? (
              <div className="d-flex justify-content-start">
                <Button
                  className="btn btn-success"
                  onClick={() => {
                    setShowModal({
                      rID: id,
                      open: !showModal.open,
                    });
                    setUpdaterefundS("Approved");
                  }}
                >
                  Refund Approved
                </Button>
                <Button
                  className="btn btn-danger"
                  onClick={() => {
                    setShowModal({
                      rID: id,
                      open: !showModal.open,
                    });
                    setUpdaterefundS("Rejected");
                  }}
                >
                  Refund Rejected
                </Button>
              </div>
            ) : null}
          </CardBody>
        </Card>
        <Modal
          size="lg"
          isOpen={showModal.open}
          toggle={() =>
            setShowModal({
              ...showModal,
              open: !showModal.open,
            })
          }
        >
          <ModalHeader>
            <Button
              className="btn btn-primary float-right"
              style={{ fontSize: "12px" }}
              size="sm"
              onClick={() =>
                setShowModal({
                  ...showModal,
                  open: false,
                })
              }
            >
              x
            </Button>
            Confirm to {updaterefundS} Refund
          </ModalHeader>

          <ModalBody className="text-center">
            <Form onSubmit={handleSubmit}>
              <h5>Are you sure?</h5>
              <Button className="btn btn-success" type="submit">
                Yes
              </Button>
              <Button
                className="btn btn-danger"
                onClick={() =>
                  setShowModal({
                    ...showModal,
                    open: false,
                  })
                }
              >
                No
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ViewRefundDetails;
